import React, { useContext, useEffect, useState } from "react";
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import { IconButton, Grow, Paper, Box, useTheme, Button, Typography } from "@mui/material";
import { CartContext } from "./CartContext";
import CartProduct from "./CartProduct";
import ReactGA from 'react-ga4';

const Cart = ({ color }) => {
    const theme = useTheme();
    const buttonTypography = theme.typography.button;
    const cart = useContext(CartContext);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    const [hover, setHover] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const handleMouseEnter = () => {
        setHover(true);
        if (timer) {
            clearTimeout(timer);
        }
    };

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
        } else if (productsCount > 0) {
            setHover(true);
            if (timer) {
                clearTimeout(timer);
            }
            const newTimer = setTimeout(() => {
                setHover(false);
            }, 2000);
            setTimer(newTimer);
        }
    }, [productsCount]);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
        } else if (productsCount > 0) {
            setHover(true);
            if (timer) {
                clearTimeout(timer);
            }
            const newTimer = setTimeout(() => {
                setHover(false);
            }, 2000);
            setTimer(newTimer);
        }
    }, [productsCount]);

    const checkout = async () => {
        try {
            const lineItems = cart.items.map(item => ({
                price: item.idStripe,
                quantity: item.quantity
            }));

            ReactGA.event({
                category: 'Ecommerce',
                action: 'begin_checkout',
                label: 'Checkout Button Clicked',
                value: cart.getTotalCost().toFixed(2)
            });

            const url = "/.netlify/functions/create-checkout-session";

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(lineItems)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.url) {
                window.location.assign(data.url); // Redirect to Stripe Checkout
            }
        } catch (error) {
            console.error("Checkout error: ", error);
        }
    };


    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setHover(false)}
            order={1}
            style={{ position: 'relative', pl: '22rem' }}
            pl={{ xs: 0, md: '1rem' }}
        >
            <IconButton aria-label="shopping cart" size={'large'} margin={0} sx={{ bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.light' }, m: 0, borderRadius: '0 28px 28px 0', height: '50px' }}>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'inline' },
                        color: 'white',
                        fontSize: buttonTypography.fontSize,
                        fontFamily: buttonTypography.fontFamily,
                        fontWeight: buttonTypography.fontWeight,
                        letterSpacing: buttonTypography.letterSpacing,
                    }}
                >
                    Cart
                </Box>
                <ShoppingBagRoundedIcon sx={{ fontSize: '1.3rem', color: color, ml: { xs: 0, md: '.5rem' } }} />
            </IconButton>

            <Grow in={hover}>
                <Paper
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => setHover(false)}
                    sx={{
                        position: 'absolute',
                        right: { xs: -30, md: 0 },
                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: "column",
                        width: 'auto',
                        padding: 1,
                        textWrap: 'nowrap',
                        maxHeight: '70vh',
                    }}
                >
                    {productsCount > 0 ? (
                        <>
                            {cart.items.map((currentProduct, idx) => (
                                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                            ))}
                            <Typography component='p' variant="body3" sx={{ m: '2rem 0 1rem 1rem' }}>
                                Total: {cart.getTotalCost().toFixed(2)}
                            </Typography>
                            <Button variant="contained" onClick={checkout} sx={{ margin: '.5rem' }}>
                                Checkout
                            </Button>
                        </>
                    ) : (
                        <Box>Your cart is empty.</Box>
                    )}
                </Paper>
            </Grow>
        </Box>
    );
};

export default Cart;
