import React from 'react';
import { Box, Card, CardMedia, Typography, Grid, } from '@mui/material';



export const ChillerPop = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ px: { xs: 2, md: 8 }, py: { xs: 6, md: 8 } }}>

            <Typography variant="h3" color="text.secondary" textAlign="center">
                Chiller
            </Typography>
            <Typography variant="h4" mt={1} mb={4} textAlign="center">
                Year round cooling.
            </Typography>

            <Grid container spacing={0} justifyContent="center">

                <Grid item xs={12} sm={12}>
                    <Card sx={{ bgcolor: 'grey.100' }}>
                        <Typography component="p" variant="body3" color="grey.600" p={5} textAlign="left">
                            Our powerful 0.8hp chiller puts out <span style={{ color: '#000000' }}> 2300W of cooling power  </span>
                            keeping your plunge cold even in the hottest climates.

                        </Typography>
                        <CardMedia
                            component="img"
                            image="../images/chiller01.webp"
                            sx={{ width: '100%', height: 'auto' }}  // Changed height to auto to maintain aspect ratio
                        />
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column-reverse', sm: 'row' },
                            bgcolor: 'grey.100',
                            my: 4,
                            alignItems: 'center',

                        }} >
                        <Box sx={{ flex: 1, p: { xs: 4, md: 7 }, py: { xs: 5, sm: 15 } }}>
                            <Typography component="p" variant="body3" color="grey.600" pb={4}>
                                Built with a <span style={{ color: '#000000' }}> GMCC ¾hp compressor </span>, a high efficiency titanium heat exchanger,
                                all encased in 18 gauge steel,
                                <span style={{ color: '#000000' }}> the AZ chiller is built to last. </span>
                            </Typography>

                        </Box>
                        <Box item display={'flex'} sx={{ maxWidth: { xs: '75%', sm: '50%' } }} >

                            <CardMedia
                                component="img"
                                image="../images/chillerT02.webp"
                                sx={{ p: { xs: 0, sm: 2 }, }}
                            />
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', bgcolor: 'grey.100' }}>

                        <Typography component="p" variant="body3" color="grey.600" py={4}>
                            Pair with the adapter or stand alone tub and cool your water as low as
                            <span style={{ color: '#000000' }}> 37F.</span>
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} sx={{}}>
                                <Card sx={{ width: '100%', height: '100%', alignItems: 'center', borderRadius: '0 0px 0px 0px' }}>
                                    <CardMedia
                                        component="img"
                                        image="../images/allThree01.webp"
                                        alt="Adapter on Tub"
                                        sx={{}}
                                    />
                                </Card>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
            </Grid >
        </Box >
    );
}



