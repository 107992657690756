import * as React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { CartContext } from '../navbar/CartContext';
import { getProductData } from '../data';

export default function ActionAreaCard(props) {
    const { price, cardStyle, disableRipple = false, children, productURL = '/shop', item, smallCart = false, setHover, dispPrice = true } = props;
    const { imgsrc, title, text } = item
    const { selectedItem, setSelectedItem } = useContext(CartContext);

    const handleClick = () => {
        setSelectedItem(item)
        setHover && setHover(false)

    }


    return (
        <Card
            elevation={0}
            sx={{
                width: '100%',
                backgroundColor: 'grey.200',
                display: 'flex', // Ensure flex layout for all sizes
                flexDirection: { xs: 'row', md: smallCart ? 'row' : 'column' }, // Horizontal on small, vertical on medium and up

            }}
        >
            <CardActionArea
                disableRipple={disableRipple}
                LinkComponent={Link}
                to={productURL}
                onClick={() => handleClick()}
                sx={{

                    width: '100%',
                    backgroundColor: 'grey.200',
                    display: { xs: 'flex', md: smallCart ? 'flex' : 'inline' },// Ensure flex layout for all sizes
                    flexDirection: { xs: 'row', md: smallCart ? 'row' : 'column' }, // Horizontal on small, vertical on medium and up
                    overflow: 'hidden',
                    height: { xs: '22vw', md: smallCart ? '80px' : 'auto' },
                    ...cardStyle
                }}
            >
                <CardMedia
                    component="img"
                    image={imgsrc}
                    alt={`${title} - ${text}`} // More descriptive alt text
                    sx={{
                        width: { xs: '35vw', md: smallCart ? '30%' : '100%' },
                        minHeight: '100%',
                        objectPosition: 'right' // Full width on medium and up, half-width on small
                        // Set a specific height or make responsive as needed


                    }}
                />
                <CardContent

                    sx={{
                        flex: '1 1 auto', // Ensures content section can grow and shrink
                        // Ensures correct visual ordering
                    }}
                >
                    <Box display={'flex'} justifyContent={'space-between'} alignContent='center'>
                        <Box >
                            <Typography gutterBottom variant="h6" sx={{
                                textWrap: 'true',
                            }}>
                                {title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{
                                display: smallCart ? 'none' : 'inline',
                                fontSize: 'clamp(.65rem, 3vw, .875rem)'

                            }
                            } >
                                {text}
                            </Typography>
                        </Box>


                        <Typography variant={smallCart ? 'h6' : 'h4'} fontWeight={300}>{price && dispPrice ? '$' + price : ''}</Typography>


                    </Box>
                    {children}
                </CardContent>
            </CardActionArea>
        </Card >
    );
}


