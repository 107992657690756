import React from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import { productsArray } from '../data'; // Adjust the path according to your project structure
import { GetInTouch } from './GetInTouch';


const websiteMap = [
    {
        section: 'Products',
        links: productsArray.map(product => ({
            name: product.title,
            url: '/shop'
        }))
    },
    {
        section: 'Learn',
        links: [
            { name: 'Setup', url: '/learn/setup' },
            { name: 'Maintenance', url: '/learn/maintenance' },
            { name: 'Troubleshooting', url: '/learn/troubleshooting' },
            { name: 'FAQ', url: '/learn/faq' }
        ]
    },
    {
        section: 'Support',
        links: [
            { name: 'About Us', url: '/support/about' },
            { name: 'Contact', url: '/support/contact' },
            { name: 'Policies', url: '/support/policies' },
            //{ name: 'Press', url: '/learn/press' }
        ]
    },



];

const Footer = () => {
    return (
        <Box component="footer" sx={{ backgroundColor: 'grey.100', color: 'grey.800', p: '2rem 0 5rem 0', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', }}>
            <Box display={'flex'} sx={{ display: 'flex', justifyContent: 'space-between', px: '5%' }}>


                <Box component='img' src='/images/logo01.webp' alt='logo' width='15rem' display={'block'} />


                <Box display={{ xs: 'none', md: 'inline' }} alignSelf={'end'} >

                    <Typography display={'inline'} >     Designed and headquartered in Cambrige, MA       </Typography>
                    <Box component='img' src='/images/af01.webp' width={'2rem'} display={'inline'} />
                </Box>
            </Box>
            {/* Add Divider here */}
            < Divider variant='middle' sx={{ my: 2, mx: 'auto', borderColor: 'grey.800', width: '90%' }} />

            <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'space-between', md: 'space-between' }, flexWrap: 'wrap', maxWidth: '1000px', mx: 'auto' }}>
                <GetInTouch />
                {websiteMap.map((section, index) => (
                    <Box key={index} sx={{ m: 2, minWidth: 100 }}>
                        <Typography variant="h6" gutterBottom>
                            {section.section}
                        </Typography>
                        {section.links.map((link, idx) => (
                            <Typography key={idx}>
                                <Link href={link.url} color="inherit" underline="none">
                                    {link.name}
                                </Link>
                            </Typography>
                        ))}
                    </Box>
                ))}

            </Box>

            <Typography textAlign={'center'} mt={'3rem'}>© Absolute Zero Plunge 2024. All Rights Reserved</Typography>



        </Box >

    );
};

export default Footer;
