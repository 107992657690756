import React from 'react';
import { Button, Paper, Grow, Typography, Box } from '@mui/material';

import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';

function SupportDropdown(props) {
    const { color, url, textTransform } = props;
    const [hover, setHover] = React.useState(false);

    const supportLinks = [
        { page: 'Contact', icon: MessageRoundedIcon, url: '/support/contact' },
        { page: 'Policies', icon: MenuBookRoundedIcon, url: '/support/policies' },
        { page: 'About Us', icon: Diversity3RoundedIcon, url: '/support/about' }
    ];

    return (
        <div className="dropdown"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', display: 'inline-block' }}>

            <Button
                to={url}
                component={Link}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: color, textTransform: textTransform }}
            >
                Support
            </Button>

            <Grow in={hover}>
                <Paper
                    sx={{
                        position: 'absolute',
                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: "column",
                        maxWidth: '1000px',
                        width: 'auto',
                        padding: 1,
                        alignItems: 'flex-start',  // Aligns children (buttons) to the start of the flex container
                    }}
                >
                    {supportLinks.map((link, idx) => (
                        <Button
                            key={idx}
                            component={Link}
                            to={link.url}
                            onClick={() => setHover(false)}
                            startIcon={<link.icon />}
                            sx={{ color: 'text.primary', justifyContent: 'flex-start', textAlign: 'left', textWrap: 'nowrap', alignSelf: 'start' }}
                        >
                            {link.page}
                        </Button>
                    ))}
                </Paper>
            </Grow>
        </div>
    );
}

export default SupportDropdown;
