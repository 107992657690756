import * as React from 'react';
import {
    Typography,
    Box, Avatar
} from '@mui/material';

import { indigo } from '@mui/material/colors';


const avaColor = indigo[100]
const iconSize = { width: '4rem', height: '4rem' };

export const AAva = () => (<Box display={'flex'} className='icons' flexDirection={'column'} alignItems={'center'}><Avatar alt="Adapter" src="/images/adapterT01.webp" sx={{ ...iconSize, bgcolor: avaColor }} slotProps={{ img: { style: { objectFit: 'cover', objectPosition: 'center 90%', padding: 5 } } }} /> <Typography variant='button' pt={1}> Adapter </Typography> </Box>);
export const CAva = () => (<Box display={'flex'} className='icons' flexDirection={'column'} alignItems={'center'}><Avatar alt="Chiller" src="/images/chillerT01.webp" sx={{ ...iconSize, bgcolor: avaColor }} slotProps={{ img: { style: { objectFit: 'cover', objectPosition: 'center 110%', padding: 7 } } }} /> <Typography variant='button' pt={1}> Chiller </Typography> </Box>);
export const TAva = () => (<Box display={'flex'} className='icons' flexDirection={'column'} alignItems={'center'}><Avatar alt="Tub" src="/images/tubT01.webp" sx={{ ...iconSize, bgcolor: avaColor }} slotProps={{ img: { style: { objectFit: 'cover', objectPosition: 'center 130%', padding: 10 } } }} /> <Typography variant='button' pt={1}> Tub </Typography></Box>);
export const FAva = () => (<Box display={'flex'} className='icons' flexDirection={'column'} alignItems={'center'}><Avatar alt="Filtration" src="/images/mf01.webp" sx={{ ...iconSize, bgcolor: avaColor }} slotProps={{ img: { style: { objectFit: 'cover', objectPosition: '50% 100%', padding: 7 } } }} /><Typography variant='button' pt={1}> Filter </Typography>  </Box>);

