import { createContext, useState, useEffect, React } from "react";
import { getProductData, productsArray } from '../data';
import ReactGA from 'react-ga4';

export const CartContext = createContext({
    items: [],
    getProductQuantity: () => { },
    addOneToCart: () => { },
    removeOneFromCart: () => { },
    deleteFromCart: () => { },
    getTotalCost: () => { },
    selectedItem: null,
    setSelectedItem: () => { },
    clearCart: () => { } // Add clearCart to the context
});

export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    // Save cartProducts to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartProducts));
    }, [cartProducts]);

    function getProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;
        return quantity === undefined ? 0 : quantity;
    }

    function addOneToCart(id, idStripe) {
        const quantity = getProductQuantity(id);

        if (quantity === 0) { // product is not in cart
            setCartProducts([...cartProducts, { id: id, quantity: 1, idStripe: idStripe }]);
            ReactGA.event({
                category: 'Ecommerce',
                action: 'add_to_cart',
                label: getProductData(id).title,
                value: getProductData(id).price
            })
        } else { // product is in cart
            ReactGA.event({
                category: 'Ecommerce',
                action: 'add_to_cart',
                label: getProductData(id).title,
                value: getProductData(id).price
            })
            setCartProducts(cartProducts.map(product =>
                product.id === id
                    ? { ...product, quantity: product.quantity + 1 }
                    : product
            ));
        }
    }

    function removeOneFromCart(id) {
        const quantity = getProductQuantity(id);

        if (quantity === 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(cartProducts.map(product =>
                product.id === id
                    ? { ...product, quantity: product.quantity - 1 }
                    : product
            ));
        }
    }

    function deleteFromCart(id) {
        setCartProducts(cartProducts.filter(product => product.id !== id));
    }

    function getTotalCost() {
        return cartProducts.reduce((totalCost, cartItem) => {
            const productData = getProductData(cartItem.id);
            return totalCost + (productData.price * cartItem.quantity);
        }, 0);
    }

    // Function to clear the cart
    function clearCart() {
        setCartProducts([]); // Clear the cart state
        localStorage.removeItem('cartItems'); // Clear the cart in localStorage
    }

    const [selectedItem, setSelectedItem] = useState(productsArray[0]);

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        selectedItem,
        setSelectedItem,
        clearCart, // Expose clearCart in the context
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;
