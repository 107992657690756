import React from 'react';
import { Button, Paper, Grow } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import BuildIcon from '@mui/icons-material/BuildRounded';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';

function LearnDropdown(props) {

    const { color, url, textTransform } = props

    const [hover, setHover] = React.useState(false);

    const learnLinks = [
        { page: 'Setup', icon: ChecklistRtlIcon },
        { page: 'Maintenance', icon: BuildIcon },
        { page: 'Troubleshooting', icon: EngineeringRoundedIcon },
        { page: 'FAQ', icon: LiveHelpRoundedIcon }
    ];

    return (
        <div className="dropdown"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', display: 'inline-block' }}>

            <Button
                to={url}
                component={Link}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: color, textTransform: textTransform }}

            >
                Learn
            </Button>

            <Grow in={hover}>
                <Paper
                    sx={{
                        position: 'absolute',
                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: "column",
                        maxWidth: '175px',
                        width: 'auto',
                        padding: 1,
                        alignItems: 'start',  // Aligns children (buttons) to the start of the flex container
                    }}
                >
                    {learnLinks.map((link) => (
                        <Button
                            key={link.page}
                            component={Link}
                            to={`/learn/${link.page.toLowerCase()}`}
                            startIcon={<link.icon />}
                            onClick={() => setHover(false)}
                            sx={{ color: 'text.primary', justifyContent: 'flex-start', textAlign: 'left', alignSelf: 'start', }}
                        >
                            {link.page}
                        </Button>
                    ))}
                </Paper>
            </Grow>
        </div>
    );
}

export default LearnDropdown;
