import React from 'react';
import { Box, Card, CardMedia, Typography, Grid, } from '@mui/material';



export const TubPop = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ px: { xs: 2, md: 8 }, py: { xs: 6, md: 8 } }}>

            <Typography variant="h3" color="text.secondary" textAlign="center">
                Tub
            </Typography>
            <Typography variant="h4" mt={1} mb={4} textAlign="center">
                Dedicated plunge.
            </Typography>

            <Grid container spacing={0} justifyContent="center">

                <Grid item xs={12} sm={12}>
                    <Card sx={{ bgcolor: 'grey.100' }}>
                        <Typography component="p" variant="body3" color="grey.600" p={5} textAlign="left">
                            Constructed from high-strength PVC, the same rugged material used in whitewater rafting boats,
                            this inflatable tub is
                            <span style={{ color: '#000000' }}> built to last. </span>
                        </Typography>
                        <CardMedia
                            component="img"
                            image="../images/cd01.webp"
                            sx={{ width: '100%', height: 'auto' }}  // Changed height to auto to maintain aspect ratio
                        />
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, bgcolor: 'grey.100', my: 4, alignItems: 'center', }}>
                        <Box sx={{ flex: 1, p: { xs: 4, md: 7 }, py: { xs: 5, sm: 15 } }}>
                            <Typography component="p" variant="body3" color="grey.600" pb={4}>
                                Setup a cold plunge in under two minutes with the included
                                <span style={{ color: '#000000' }}> high flow pump.</span>

                            </Typography>
                            <Typography component="p" variant="body3" color="grey.600">
                                Easily deflate and  <span style={{ color: '#000000' }}>take it on the go</span> ensuring you never miss a session.
                            </Typography>
                        </Box>
                        <Box item display={'flex'} sx={{ maxWidth: { xs: '75%', sm: '50%' } }} >

                            <CardMedia
                                component="img"
                                image="../images/tubT02.webp"
                                sx={{ p: { xs: 0, sm: 2 }, }}
                            />
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', bgcolor: 'grey.100' }}>

                        <Typography component="p" variant="body3" color="grey.600" py={4}>
                            A cold plunge for
                            <span style={{ color: '#000000' }}> every space.</span>
                        </Typography>

                        <Grid container spacing={2}>
                            {/* <Grid item xs={6} sm={4}>
                                <Card sx={{ width: '100%', height: 250, overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        image="../images/lind04.webp"
                                        alt="Adapter in Bath 1"
                                        sx={{ width: '100%', height: '100%' }}
                                    />
                                </Card>
                            </Grid> */}



                            <Grid item xs={12} md={12} sx={{}}>
                                <Card sx={{ width: '100%', height: '100%', alignItems: 'center', borderRadius: '0 0px 0px 0px' }}>
                                    <CardMedia
                                        component="img"
                                        image="../images/ld01.webp"
                                        alt="Adapter on Tub"
                                        sx={{}}
                                    />
                                </Card>
                            </Grid>
                            {/* 
                            <Grid item xs={12} sm={8}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        image="../images/lind03.webp"
                                        alt="Adapter"
                                        sx={{ width: '100%', height: '100%' }}
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        image="../images/ml01.webp"
                                        alt="Adapter on Stock Tank"
                                        sx={{ width: '100%', height: '100%' }}
                                    />
                                </Card>
                            </Grid> */}

                        </Grid>
                    </Card>
                </Grid>
            </Grid >
        </Box >
    );
}



