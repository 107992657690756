import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <Box className='flexColumn centered' sx={{
            position: 'relative',
            //backgroundImage: "url('/images/ld02.webp')",
            //backgroundPosition: 'center',

            minHeight: '100vh',
            width: '100%',
            py: { xs: '5rem', md: 0 }

        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: 'linear-gradient(30deg, hsla(244, 100%, 60%, .3) 0%,hsla(244, 0%, 100%, .5) 35%, hsla(27, 100%, 100%, .5) 65%, hsla(27, 100%, 79%, .5) 100%)', // Adjust the alpha value to make it darker or lighter
                zIndex: 1,
            }} />
            <Box className='flexColumn centered' sx={{
                maxWidth: { xs: '96vw', md: 1200 },
                mx: '2vw',
                p: { xs: '2rem', md: '5rem' },
                zIndex: 2,
                bgcolor: '#ffffffff',
                borderRadius: '28px',
                border: '1px solid #aaaaaa',
                boxSizing: 'border-box'
            }}>
                <Typography variant='h1' component={'h1'} textAlign={'center'}>
                    404
                </Typography>
                <Typography variant='body1' textAlign={'center'}>
                    Page Not Found
                </Typography>
                <Button variant="text" component={Link} to="/" sx={{ mx: 'auto' }}>
                    Return Home
                </Button>
            </Box>
        </Box>
    )
}

export default NotFound