import React from 'react';
import { Box, Card, CardMedia, Typography, Grid, useTheme } from '@mui/material';



export const AdapterPop = () => {
    const theme = useTheme()
    return (

        <Box display="flex" flexDirection="column" alignItems="center" sx={{ px: { xs: 2, md: 8 }, py: { xs: 6, md: 8 } }}>

            <Typography variant="h3" color="text.secondary" textAlign="center">
                Adapter
            </Typography>
            <Typography variant="h4" mt={1} mb={4} textAlign="center">
                Any tub, anywhere.
            </Typography>

            <Grid container spacing={0} justifyContent="center">

                <Grid item xs={12} sm={12}>
                    <Card sx={{ bgcolor: 'grey.100' }}>
                        <Typography component="p" variant="body3" color="grey.600" p={5} textAlign="left">
                            DIY cold plunge without the hassle. Our simple plug and play system
                            allows you to <span style={{ color: '#000000' }}>turn anything into a cold plunge.</span>
                        </Typography>
                        <CardMedia
                            component="img"
                            image="../images/lind01.webp"
                            sx={{ width: '100%', height: 'auto' }}  // Changed height to auto to maintain aspect ratio
                        />
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, bgcolor: 'grey.100', my: 4, alignItems: 'center', }}>
                        <Box sx={{ flex: 1, p: { xs: 4, md: 7 }, py: { xs: 5, sm: 15 } }}>
                            <Typography component="p" variant="body3" color={theme.palette.text.light} pb={4}>
                                Equipped with <span style={{ color: '#000000' }}>two ¾-inch barbed fittings,</span> the adapter
                                connects hoses from the chiller&apos;s inlet and outlet for efficient
                                water circulation.
                            </Typography>
                            <Typography component="p" variant="body3" color={theme.palette.text.light}>
                                Using the two thumb screws along the sides the rear slider
                                is <span style={{ color: '#000000' }}>adjustable up to 7 inches</span> ensuring a secure fit on any tub.
                            </Typography>
                        </Box>
                        <Box item display={'flex'} sx={{ maxWidth: { xs: '75%', sm: '50%' } }} >

                            <CardMedia
                                component="img"
                                image="../images/adapterFittings01.webp"
                                sx={{ p: { xs: 0, sm: 2 }, }}
                            />
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', bgcolor: 'grey.100' }}>

                        <Typography component="p" variant="body3" color={theme.palette.text.light} py={4}>
                            Convert bathtubs, stock tanks
                            <span style={{ color: '#000000' }}> and more.</span>
                        </Typography>

                        <Grid container spacing={2}>




                            <Grid item xs={12} md={12} sx={{}}>
                                <Card sx={{ width: '100%', height: '100%', alignItems: 'center', borderRadius: '0 0px 0px 0px' }}>
                                    <CardMedia
                                        component="img"
                                        image="../images/lind03.webp"
                                        alt="Adapter on Tub"
                                        sx={{}}
                                    />
                                </Card>
                            </Grid>


                        </Grid>
                    </Card>
                </Grid>
            </Grid >
        </Box >

    );
}



