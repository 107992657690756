import React from "react";
import { CartContext } from "./CartContext";
import { useContext } from "react";
import { getProductData } from "../data";
import { Box, Button, Typography } from "@mui/material";
import ActionAreaCard from "../StockComponents/ActionAreaCard";

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);
    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        cart.removeOneFromCart(id)
    }

    const removeButton = <Button color='error' onClick={handleClick} p='.5rem'> Remove</Button >
    const qtyText = <Typography display={{ xs: 'none', md: 'inline' }}>{quantity}x</Typography>
    const mobilePrice = <Typography variant="button" display={{ xs: 'inline', md: 'none' }} p={0}>${productData.price}</Typography>
    return (

        <ActionAreaCard
            item={productData}
            price={window.innerWidth > 900 ? productData.price : quantity}
            cardStyle={{ width: '320px', mt: '.5rem' }}
            disableRipple={true}
            productURL='/shop'
            smallCart={cart.items.length > 1 ? true : false}
            dispPrice={false}

        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',

                    justifyContent: 'space-between',
                    alignItems: 'center',


                }}>
                {mobilePrice} {removeButton} {qtyText}
            </Box>
        </ActionAreaCard>

    )
}

export default CartProduct;