import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { InstaIcon, TikTokIcon } from './SocialIcons';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Link } from 'react-router-dom';

export const GetInTouch = (props) => {


    return (
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'start', width: { xs: '100%', md: '25%' }, my: { xs: '2rem', md: '1rem' } }} {...props}>
            <Typography variant="h6" gutterBottom>
                Get in Touch
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, color: 'grey.500' }}>
                <IconButton
                    component={Link}
                    to="https://www.instagram.com/absolutezeroplunge"
                    target="_blank"
                    color="inherit"
                    sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', } }}
                >
                    <InstaIcon />
                </IconButton>
                <IconButton
                    component={Link}
                    to="https://www.facebook.com/absolutezeroplunge"
                    target="_blank"
                    color="inherit"
                    sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)' } }}
                >
                    <FacebookRoundedIcon />
                </IconButton>
                <IconButton
                    component={Link}
                    to="https://www.tiktok.com/@absolutezeroplunge"
                    target="_blank"
                    color="inherit"
                    sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)' } }}
                >
                    <TikTokIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
                <IconButton
                    component={Link}
                    to="mailto:hello@absolutezeroplunge.com"
                    color="inherit"
                    sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)' } }}
                >
                    <EmailRoundedIcon />
                </IconButton>
            </Box>
        </Box>
    )
}
