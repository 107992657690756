import React from 'react';
import { Button, Paper, Grow, Typography, Box } from '@mui/material';
import ActionAreaCard from '../StockComponents/ActionAreaCard';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { productsArray } from '../data';

function ProductsDropdown(props) {
    const { color, url, textTransform } = props;

    const [hover, setHover] = React.useState(false);

    return (
        <div className="dropdown"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', display: 'inline-block' }}>

            <Button to={url} component={Link} endIcon={<ArrowDropDownIcon />} sx={{ color: color, textTransform: textTransform }} >
                Products
            </Button>

            <Grow in={hover} >
                <Paper
                    sx={{
                        position: 'absolute',

                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: "column",
                        maxWidth: '1000px',
                        width: { xs: '80vw', md: '50vw' },
                        padding: 1,
                    }}
                >
                    <Typography variant='h3' fontSize={'1.5rem'} fontWeight={600} sx={{ padding: 2 }}>Cold Plunges</Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', }}>
                        {productsArray.slice(0, 3).map((item, idx) => (
                            <Box m={1} key={idx}>
                                <ActionAreaCard key={idx} item={item} setHover={setHover} />
                            </Box>
                        ))}
                    </Box>
                    <Button
                        variant='outlined'
                        color='primary'
                        to={'/products'}
                        component={Link}
                        onClick={() => setHover(false)}

                        sx={{
                            marginY: 2,
                            marginX: 2,
                            padding: '.25rem', // Adjust padding to desired size
                            textTransform: 'none', // Prevent uppercase transformation
                            minWidth: 'auto', // Ensure min-width is auto to fit the text content
                            display: 'inline',
                            maxWidth: '6rem',
                            textAlign: 'center'
                        }}
                    >
                        View All
                    </Button>
                </Paper>
            </Grow>
        </div>
    );
}

export default ProductsDropdown;

