const setSVH = () => {
    const svh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--svh', `${svh}px`);
};

import { useEffect } from 'react';

const UseSVH = () => {
    useEffect(() => {
        // Set the initial value
        setSVH();

        // Update the value on resize
        //window.addEventListener('resize', setSVH);
        window.addEventListener('load', setSVH);

        // Clean up the event listener on unmount
        return () => window.removeEventListener('load', setSVH);
    }, []);
};

export default UseSVH;
